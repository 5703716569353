<template lang="pug">
component(:is="isAuth ? Default : Dashboard")
  slot
</template>

<script setup lang="ts">
import Default from './default.vue';
import Dashboard from './dashboard.vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

const { isAuth } = useUserAccess();
</script>
